@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

/* width */
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

.stop-marker {
  transform: translate(-50%, -50%)
}

.vehicle-marker {
  transform: translate(-50%, -50%)
}
